<template>
    <div class="config">
        <div>我的任务</div>
        <div class="flex flex-child-center">
            <div>2022年11月月度进度条返利</div>
            <div>返利</div>
        </div>
        <div class="flex flex-child-center">
            <div>
                <div>开始时间</div>
                <div>2022-11-01 00:00:00</div>
            </div>
            <div>
                <div>结束时间</div>
                <div>2022-11-01 00:00:00</div>
            </div>
        </div>
        <div class="flex flex-child-center">
            <div>台阶达成金额</div>
            <div>¥1600.00</div>
        </div>
        <div class="flex flex-child-center">
            <div>台阶达成金额</div>
            <div>¥800.00</div>
        </div>
        <div class="flex flex-child-center">

        </div>
        <div class="flex flex-child-center margin-30 no-margin-bottom no-margin-left no-border-right">
            <div>
                <div>最新刷新时间</div>
                <div>2022-11-01 00:00:00</div>
            </div>
        </div>
        <div class="line"></div>
    </div>
</template>

<script>

export default {
    name: "task",
    data() {
        return {
            form: {
                hospital_name: '',
                second_name: '',
                logo: ''
            },
        }
    },
    mounted() {
        // this.onList();
    },
    methods: {
        // onList() {
        //     this.$axios.post('api/setting/config_info', {}).then(res => {
        //         this.form = res.data.data ||  {};
        //         this.$store.commit('setHospital', this.form);
        //     })
        // },
    },
}
</script>

<style lang="scss" scoped>
.config {
    height: 660px;
    padding: 0.24rem 0.34rem;
    .line {
        height: 10px;
        background: #F6F6F6;
        margin-top: 15px;
    }
    >div:nth-child(1) {
        font-size: 0.18rem;
        font-weight: 400;
        color: #333333;
        padding-bottom: 0.1rem;
        border-bottom: 0.01rem solid #E4E4E4;
    }
    >div:nth-child(2) {
        font-size: 0.16rem;
        font-weight: 500;
        color: #2F2F2F;
        margin-top: 22px;
        margin-bottom: 18px;
        >div:nth-child(2) {
            width: 33px;
            height: 0.18rem;
            background: #CF7AE2;
            font-size: 0.12rem;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 0.18rem;
            margin-left: 15px;
            text-align: center;
        }
    }
    >div:nth-child(3),>div:nth-child(7) {
        font-size: 0.12rem;
        font-weight: 400;
        color: #666666;
        >div:nth-child(2) {
            margin-left: 61px;
        }
        >div:nth-child(1) , >div:nth-child(2) {
            display: flex;
            align-items: center;
            >div:nth-child(2) {
                font-weight: 500;
                color: #2F2F2F;
                margin-left: 0.1rem;
            }
        }
    }
    >div:nth-child(4),>div:nth-child(5) {
        font-size: 0.12rem;
        font-weight: 400;
        color: #666666;
        margin-top: 22px;
        >div:nth-child(2) {
            font-size: 0.2rem;
            font-weight: normal;
            color: #4B83F0;
            line-height: 0.2rem;
            margin-left: 0.1rem;
        }
    }
    >div:nth-child(5) {
        margin-top: 0.1rem;
        >div:nth-child(2) {
            font-size: 0.12rem;
            font-weight: 500;
        }
    }
}
</style>
